import React from 'react';
import './LandingPage.css';
import MatrixBackground from './MatrixBackground';

const App = () => {
  return (
    <>
      <MatrixBackground timeout={50} />
      <div className="flicker-overlay"></div>
      <div className="landing-page">
        <div className="center-content">
          <h1 className="headline">info@psyop.gg</h1>
          <img src="https://img.ifunny.co/images/6f1a8dc79dfcaae75c8f2dede52a91e643b4cb1cf88b6ed282534144eb6cee94_1.webp" alt="Psyops Theme" className="image" />
          <p className="paragraph">
           It just began.  #Based 
          </p>
        </div>
      </div>
    </>
  );
};

export default App;
